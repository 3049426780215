document.addEventListener("turbolinks:load", function() {
  // Prepare modals
  const modals = document.querySelectorAll('.modal')
  for (const modal of modals) {
    const innerHTML = modal.innerHTML
    modal.innerHTML = `
      <div class="backlayer"></div>
      <div class="modal-content">
        <div class="modal-close">
          <i class="fa fa-times fa-lg"></i>
        </div>
        <div class="inner-content"></div>
      </div>`
    modal.querySelector('.inner-content').innerHTML = innerHTML
  }

  // Check for click on modal openers
  setTimeout(() => {
    const elems = document.querySelectorAll('[data-modal]')
    for (const elem of elems) {
      elem.onclick = (event) => {
        event.preventDefault()

        const modalTarget = elem.dataset.modal
        const modal = document.getElementById(modalTarget)

        if (modal) {
          modal.classList.add('show')
        }
      }
    }

    const modals = document.querySelectorAll('.modal')
    for (const modal of modals) {
      modal.querySelector('.modal-close').onclick = () => {
        modal.classList.remove('show')
      }
    }
  }, 250)
})