import Vue from 'vue/dist/vue.min'

document.addEventListener("turbolinks:load", function() {
  if (document.getElementById('baseData')) {
    const app = new Vue({
      el: '#baseData',
      data () {
        return {
          currentPassword: '',
          newPassword: '',
          newPasswordConfirmation: '',

          isLoading: false,
          invalidCurrentPassword: false,
          showSuccess: false
        }
      },
      computed: {
        isValid () { return this.currentPassword && this.newPassword && this.newPasswordConfirmation && this.newPassword === this.newPasswordConfirmation },
        passwordNotMatch () { return this.newPassword && this.newPasswordConfirmation && this.newPassword !== this.newPasswordConfirmation }
      },
      watch: {
        currentPassword () { this.invalidCurrentPassword = false }
      },
      methods: {
        async changePassword () {
          if (!this.isValid || this.isLoading) { return }

          this.isLoading = true

          const res = await fetch('/auth/auth-change-password', {
            method: 'POST',
            body: JSON.stringify({ current_password: this.currentPassword, new_password: this.newPassword }),
            headers: { 'Content-Type': 'application/json' }
          })

          if (res.status === 200) {
            this.currentPassword = ''
            this.newPassword = ''
            this.newPasswordConfirmation = ''

            this.showSuccess = true
            setTimeout(() => { this.showSuccess = false }, 2000)
          } else {
            this.invalidCurrentPassword = true
          }

          this.isLoading = false
        }
      }
    })
  }
})
