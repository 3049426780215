import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    setStatus: String
  }

  setStatus(event) {
    const id = event.params.id
    const status = event.target.value

    fetch('/dashboard/set-invoice-status', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({ id, status })
    })
  }
}
