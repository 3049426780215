import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  static values = {
    setSalesRepInterval: Number
  }

  setReceivedWelcomePackage(event) {
    const userId = event.params.id
    const receivedWelcomePackage = event.target.checked

    fetch('/dashboard/set-receive-welcome-package', {
      method: 'POST',
      headers: { 'Content-Type': 'application/json' },
      body: JSON.stringify({
        id: userId,
        received_welcome_package: receivedWelcomePackage
      })
    })
  }
}