import { Controller } from '@hotwired/stimulus'

export default class extends Controller {
  changeLocale(event) {
    const url = window.location.pathname
    const params = new URLSearchParams(window.location.search)

    params.set('locale', event.target.value)

    Turbolinks.visit(url + '?' + params, { action: 'replace' })
  }
}
