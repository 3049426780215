import Vue from 'vue/dist/vue.min'

document.addEventListener("turbolinks:load", function() {
  if (document.getElementById('cardPersonalization')) {
    window.app = new Vue({
      el: '#cardPersonalization',
      data () {
        return {
          isActive: document.getElementById('cardPersonalization').dataset.active === 'true',
          name: document.getElementById('cardPersonalization').dataset.name
        }
      },
      computed: {
        nameValid () {
          return /^[a-zA-Z0-9äöüÄÖÜ\s\.\-\/\'\&\~\|\(\)\[\]\{\}]*$/gm.test(this.name)
        },
        showName () {
          return this.isActive && this.name ? this.name : 'soskarte.de'
        }
      },
      watch: {
        name (val) {
          if (val.length > 30) { this.name = val.slice(0, 30) }
        }
      }
    })
  }
})
