// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from '@rails/ujs'
import Turbolinks from 'turbolinks'
import * as ActiveStorage from '@rails/activestorage'

import 'channels'
import '../stylesheets/application'
import '../controllers'

// Other
import './letterHighlight'
import './modals'
import './customerDashboardNav'

// Vue
import './newOrder'
import './baseData'
import './help'
import './cardPersonalization'

// Local Time
import LocalTime from 'local-time'
LocalTime.start()

// Serviceworker for PWA
window.addEventListener('load', () => {
  navigator.serviceWorker.register('/service-worker.js').then(registration => {
    console.log('ServiceWorker registered: ', registration)

    var serviceWorker
    if (registration.installing) {
      serviceWorker = registration.installing
      console.log('Service worker installing.')
    } else if (registration.waiting) {
      serviceWorker = registration.waiting
      console.log('Service worker installed & waiting.')
    } else if (registration.active) {
      serviceWorker = registration.active
      console.log('Service worker active.')
    }
  }).catch(registrationError => {
    console.log('Service worker registration failed: ', registrationError)
  })
})

Rails.start()
Turbolinks.start()
ActiveStorage.start()
