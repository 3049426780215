import Vue from 'vue/dist/vue.min'

document.addEventListener("turbolinks:load", function() {
  if (document.getElementById('help')) {
    const app = new Vue({
      el: '#help',
      data () {
        return {
          showHelp: 0,
          isLoading: false,
          showSuccess: false,

          mail: '',
          name: '',
          request: ''
        }
      },
      methods: {
        setShowHelp (index) {
          this.showHelp = this.showHelp === index ? -1 : index
        },
        isValid () {
          return this.mail && this.name && this.request
        },
        async sendHelpRequest () {
          const { mail, name, request } = this

          if (!this.isValid || this.isLoading) { return }

          this.isLoading = true

          await fetch('/dashboard/send-help-request', {
            method: 'POST',
            body: JSON.stringify({ mail, name, request }),
            headers: { 'Content-Type': 'application/json' }
          })

          this.mail = ''
          this.name = ''
          this.request = ''

          this.showSuccess = true
          setTimeout(() => { this.showSuccess = false }, 2000)

          this.isLoading = false
        }
      }
    })
  }
})
