import Vue from 'vue/dist/vue.min'

document.addEventListener("turbolinks:load", function() {
  if (document.getElementById('newOrder')) {
    window.app = new Vue({
      el: '#newOrder',
      data () {
        return {
          cardPersonalizationIsActive: document.getElementById('cardPersonalization').dataset.active === 'true',
          cardPersonalizationName: document.getElementById('cardPersonalization').dataset.name,
          step: 0,

          street: '',
          zip: '',
          city: '',
          co: '',

          name: '',
          emergencyContactName: '',
          emergencyContactPhone: '',
          emergencyInfo: [],
          emergencyInfoInput: '',
          emergencyNotice: '',
          secondCard: false,

          autocomplete: null,
          autocompletePlaces: [],

          firstName: '',
          lastName: ''
        }
      },
      computed: {
        zipInvalid () {
          return this.zip && !/^\d{5}$/gm.test(this.zip)
        },
        streetInvalid () {
          return this.street && !/\d{1,}/gm.test(this.street)
        },
        dataValid () {
          return ![this.name, this.emergencyContactName, this.emergencyInfo.join(', '), this.emergencyNotice].some((d) => {
            return /^[a-zA-Z0-9äöüÄÖÜß!?\s\.\+\,\-\/\'\&\~\:\|\(\)\[\]\{\}]*$/gm.test(d) === false
          })
        },
        phoneNumberValid () {
          return /^[0-9\s\/\+\-]*$/gm.test(this.emergencyContactPhone)
        },
        emergencyInfoInvalid () {
          return (this.emergencyInfo.join(', ') + ', ' + this.emergencyInfoInput).length > 60
        },
        cardPersonalizationShowName () {
          return this.cardPersonalizationIsActive && this.cardPersonalizationName ? this.cardPersonalizationName : 'soskarte.de'
        },
        emergencyContactPhoneWarning () {
          return this.emergencyContactPhone && this.emergencyContactPhone.replaceAll(' ', '').startsWith('+490')
        }
      },
      watch: {
        emergencyInfoInput: function (val) {
          if (val.endsWith(',')) {
            const arr = val.split(',')
            const value = arr[arr.length - 2].replaceAll(',', '').trim()
            if ((this.emergencyInfo.join(',') + ', ' + value).length <= 60 && value.length) {
              this.emergencyInfo.push(value)
              this.emergencyInfoInput = ''
            }
          }
        }
      },
      mounted () {
        this.autocomplete = new google.maps.places.Autocomplete(this.$refs.streetSearch, {
          fields: ['address_components']
        })

        google.maps.event.addListener(this.autocomplete, 'place_changed', () => {
          try {
            const components = this.autocomplete.getPlace().address_components
            this.street = components.find(c => c.types[0] === 'route').long_name + ' ' + components.find(c => c.types[0] === 'street_number').long_name
            this.zip = components.find(c => c.types[0] === 'postal_code').long_name
            this.city = components.find(c => c.types[0] === 'locality').long_name

            if (this.street.includes(',')) {
              this.street = this.street.split(',')[0]
            }
          } catch (e) {
            this.street = this.zip = this.city = ''
          }
        })
      },
      methods: {
        nextStep () {
          if (this.step === 0 && this.street && this.city && this.zip && !this.zipInvalid && !this.streetInvalid && this.firstName !== this.co && this.firstName !== this.lastName) { this.step += 1 }
          else if (this.step === 1 && this.dataValid && this.phoneNumberValid) { this.step += 1 }
          else if (this.step === 2) { this.createOrder(); this.step += 1 }
        },
        goBack () {
          if (this.step <= 2 && this.step >= 1) { this.step -= 1 }
        },
        createOrder () {
          fetch(`/orders/create`, {
            method: 'POST',
            body: JSON.stringify({
              first_name: this.firstName,
              last_name: this.lastName,
              street: this.street,
              c_o: this.co,
              zip: this.zip,
              city: this.city,
              is_second_card: this.secondCard,
              name: this.name,
              emergency_contact_name: this.emergencyContactName,
              emergency_contact_phone: this.emergencyContactPhone,
              emergency_info: this.emergencyInfo.join(', '),
              emergency_notice: this.emergencyNotice
            }),
            headers: { 'Content-Type': 'application/json' }
          })
        },
        sendToPharmacy (pharmacyName, pharmacyStreet, pharmacyZip, pharmacyCity) {
          this.street = pharmacyStreet
          this.zip = pharmacyZip
          this.co = `${this.firstName} ${this.lastName}`
          this.firstName = pharmacyName
          this.lastName = ''
          this.city = pharmacyCity

          this.nextStep()
        },
        removeInfo (index) {
          this.emergencyInfo = this.emergencyInfo.filter((k, i) => i !== index)
        },
        emergencyInputBlur () {
          const value = this.emergencyInfoInput
          if ((this.emergencyInfo.join(',') + ', ' + value).length <= 60 && value.length) {
            this.emergencyInfo.push(value)
            this.emergencyInfoInput = ''
          }
        }
      }
    })
  }
})
